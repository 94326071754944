
/**
 * @module apollon-emphasize/emphasizeediting
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import EmphasizeCommand from './emphasizeCommand';

const EMPHASIZE = 'emphasize';

/**
 * The emphasize editing feature.
 *
 * It registers the `'emphasize'` command, the <kbd>Ctrl+I</kbd> keystroke and introduces the `emphasize` attribute in the model
 * which renders to the view as an `<em>` element.
 *
 * @extends module:core/plugin~Plugin
 */
export default class Emphasizeediting extends Plugin {
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;

        // Allow italic attribute on text nodes.
        editor.model.schema.extend( '$text', { allowAttributes: EMPHASIZE } );

        editor.conversion.attributeToElement( {
            model: EMPHASIZE,
            view: 'em',
            upcastAlso: [
                'i',
                {
                    styles: {
                        'font-style': 'italic'
                    }
                }
            ]
        } );

        // Create italic command.
        editor.commands.add( EMPHASIZE, new EmphasizeCommand( editor, EMPHASIZE ) );

        // Set the Ctrl+I keystroke.
        editor.keystrokes.set( 'CTRL+I', EMPHASIZE );
    }
}
