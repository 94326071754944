
/**
 * @module apollon-emphasize/src/emphasize
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import EmphasizeEditing from './emphasizeediting';
import EmphasizeUI from './emphasizeui';

/**
 * The Emphasize feature.
 *
 * This is a "glue" plugin which loads the {@link module:apollon-emphasize/src/emphasizeediting~EmphasizingEditing} and
 * {@link module:apollon-emphasize/src/emphasizeui~EmphasizeUI} plugins.
 *
 * @extends module:core/plugin~Plugin
 */
export default class Emphasize extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [ EmphasizeEditing, EmphasizeUI ];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'Emphasize';
    }
}
