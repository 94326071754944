
/**
 * @module apollon-small/src/smallediting
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import SmallCommand from './smallCommand';

const SMALL = 'small';

/**
 * The small editing feature.
 *
 * It registers the `'small'` command, the <kbd>Ctrl+I</kbd> keystroke and introduces the `small` attribute in the model
 * which renders to the view as an `<small>` element.
 *
 * @extends module:core/plugin~Plugin
 */
export default class Smallediting extends Plugin {
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;

        // Allow italic attribute on text nodes.
        editor.model.schema.extend( '$text', { allowAttributes: SMALL } );

        editor.conversion.attributeToElement( {
            model: SMALL,
            view: 'small',
            upcastAlso: [
                {
                    styles: {
                        'font-size': 'smaller'
                    }
                }
            ]
        } );

        // Create italic command.
        editor.commands.add( SMALL, new SmallCommand( editor, SMALL ) );
    }
}
