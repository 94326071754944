
/**
 * @module apollon-emphasize/emphasizeui
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import markIcon from '../theme/icons/highlighter-solid.svg';

const MARK = 'mark';

/**
 * The mark UI feature. It introduces the Mark button.
 *
 * @extends module:core/plugin~Plugin
 */
export default class MarkUI extends Plugin {
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const t = editor.t;

        // Add bold button to feature components.
        editor.ui.componentFactory.add( MARK, locale => {
            const command = editor.commands.get( MARK );
            const view = new ButtonView( locale );

            view.set( {
                label: t( 'mark' ),
                icon: markIcon,
                tooltip: true
            } );

            view.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            // Execute command.
            this.listenTo( view, 'execute', () => editor.execute( MARK ) );

            return view;
        } );
    }
}
