
/**
 * @module apollon-emphasize/emphasizeui
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import italicIcon from '../theme/icons/italic.svg';

const EMPHASIZE = 'emphasize';

/**
 * The emphasize UI feature. It introduces the Emphasize button.
 *
 * @extends module:core/plugin~Plugin
 */
export default class EmphasizeUI extends Plugin {
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const t = editor.t;

        // Add bold button to feature components.
        editor.ui.componentFactory.add( EMPHASIZE, locale => {
            const command = editor.commands.get( EMPHASIZE );
            const view = new ButtonView( locale );

            view.set( {
                label: t( 'Emphasize' ),
                icon: italicIcon,
                keystroke: 'CTRL+I',
                tooltip: true
            } );

            view.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            // Execute command.
            this.listenTo( view, 'execute', () => editor.execute( EMPHASIZE ) );

            return view;
        } );
    }
}
