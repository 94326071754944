
/**
 * @module apollon-small/smallui
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import smallIcon from '../theme/icons/text-height.svg';

const SMALL = 'small';

/**
 * The small UI feature. It introduces the Small button.
 *
 * @extends module:core/plugin~Plugin
 */
export default class SmallUI extends Plugin {
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const t = editor.t;

        // Add bold button to feature components.
        editor.ui.componentFactory.add( SMALL, locale => {
            const command = editor.commands.get( SMALL );
            const view = new ButtonView( locale );

            view.set( {
                label: t( 'Small' ),
                icon: smallIcon,
                tooltip: true
            } );

            view.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            // Execute command.
            this.listenTo( view, 'execute', () => editor.execute( SMALL ) );

            return view;
        } );
    }
}
