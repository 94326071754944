/**
 * @module apollon-emphasize/src/emphasizeCommand
 */

import Command from '@ckeditor/ckeditor5-core/src/command';

/**
 * The special characters command.
 *
 * @extends module:core/command~Command
 */
export default class EmphasizeCommand extends Command {
    /**
     * Creates an instance of the command.
     *
     * @param {module:core/editor/editor~Editor} editor Editor instance.
     */
    constructor(editor, attributeKey) {
        super(editor);
        this.attributeKey = attributeKey;
    }

    /**
     * Updates the command's {@link #value} and {@link #isEnabled} based on the current selection.
     */
    refresh() {
        const model = this.editor.model;
        const doc = model.document;

        this.value = this._getValueFromFirstAllowedNode();
        this.isEnabled = model.schema.checkAttributeInSelection( doc.selection, this.attributeKey );
    }

    /**
     * @param {Object} [options] Options for the executed command.
     * @param {String} [options.value] The value to insert.
     * @fires execute
     */
    execute(options = {}) {
        const model = this.editor.model;
        const doc = model.document;
        const selection = doc.selection;
        const value = ( options.forceValue === undefined ) ? !this.value : options.forceValue;

        model.change( writer => {
            if ( selection.isCollapsed ) {
                if ( value ) {
                    writer.setSelectionAttribute( this.attributeKey, true );
                } else {
                    writer.removeSelectionAttribute( this.attributeKey );
                }
            } else {
                const ranges = model.schema.getValidRanges( selection.getRanges(), this.attributeKey );

                for ( const range of ranges ) {
                    if ( value ) {
                        writer.setAttribute( this.attributeKey, value, range );
                    } else {
                        writer.removeAttribute( this.attributeKey, range );
                    }
                }
            }
        } );
    }

    /**
     * Checks the attribute value of the first node in the selection that allows the attribute.
     * For the collapsed selection returns the selection attribute.
     *
     * @private
     * @returns {Boolean} The attribute value.
     */
    _getValueFromFirstAllowedNode() {
        const model = this.editor.model;
        const schema = model.schema;
        const selection = model.document.selection;

        if ( selection.isCollapsed ) {
            return selection.hasAttribute( this.attributeKey );
        }

        for ( const range of selection.getRanges() ) {
            for ( const item of range.getItems() ) {
                if ( schema.checkAttribute( item, this.attributeKey ) ) {
                    return item.hasAttribute( this.attributeKey );
                }
            }
        }

        return false;
    }
}
