
/**
 * @module apollon-small/src/small
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import MarkEditing from './markediting';
import MarkUI from './markui';

/**
 * The Small feature.
 *
 * This is a "glue" plugin which loads the {@link module:basic-styles/italic/italicediting~ItalicEditing} and
 * {@link module:basic-styles/italic/italicui~ItalicUI} plugins.
 *
 * @extends module:core/plugin~Plugin
 */
export default class Small extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [ MarkEditing, MarkUI ];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'Mark';
    }
}
