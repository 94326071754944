
/**
 * @module apollon-mark/src/markediting
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import MarkCommand from './markCommand';

const MARK = 'mark';

/**
 * The mark editing feature.
 *
 * It registers the `'mark'` command, the <kbd>Ctrl+I</kbd> keystroke and introduces the `mark` attribute in the model
 * which renders to the view as an `<mark>` element.
 *
 * @extends module:core/plugin~Plugin
 */
export default class Markediting extends Plugin {
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;

        // Allow italic attribute on text nodes.
        editor.model.schema.extend( '$text', { allowAttributes: MARK } );

        editor.conversion.attributeToElement( {
            model: MARK,
            view: 'mark',
            upcastAlso: [
                {
                    styles: {
                        'background-color': 'yellow',
                        'color': 'black'
                    }
                }
            ]
        } );

        // Create italic command.
        editor.commands.add( MARK, new MarkCommand( editor, MARK ) );
    }
}
